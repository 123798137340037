<template>
    <div v-if="isMissionReady && items">
        <b-table :items="items" :fields="fields" :tbody-tr-class="rowClass">           
            <template #cell(activity)="data">
                <span class="click-able"> {{ (isMissionReady && data.item.insert_datetime != "Total") ? convertActivity(data.item) : "" }} </span>
            </template>
        </b-table>       
    </div>
</template>
<script>
import service from '../../service'
import XLSX from "xlsx"
export default {
    props:{
        items: {type: Array, default: null},
        is_loading: {type: Boolean, default: true},
        query: {type: Object, default: null}
    },
    data(){
        return {
            fields: [
                {
                    key: "occur_date",
                    label: "Date",
                    thClass: "table-success",
                    formatter: value => {
                        return this.formatDate(value)
                    },
                },
                {
                    key: "balance_change",
                    label: "Earned GGT",
                    thClass: "table-success"
                },
                {
                    key: "activity",
                    label: "Activity",
                    thClass: "table-success"
                },
            ],
            current_item: null,
            missions: [],
            isMissionReady: false
        }
    },
    watch:{
        items: {
            handler: function () {
                if (this.items[0].occur_date != "Total"){
                    this.items.unshift({occur_date: "Total", balance_change: this.calTotalEarn(), activity: ""})
                }
            },
            deep: true
        }
    },
    created(){
        this.getMission();
    },
    methods: {
        async getMission(){
            let response = await service.getAllMissionDaily();
            if (response.data.type === "DATA") {
                this.missions = [...this.missions, ...response.data.data.list];
            }
            response = await service.getAllMissionEvent();
            if (response.data.type === "DATA") {
                this.missions = [...this.missions, ...response.data.data.list];
            }
            response = await service.getAllMissionNewbie();
            if (response.data.type === "DATA") {
                this.missions = [...this.missions, ...response.data.data.list];
            }
            this.isMissionReady = true;
        },
        convertActivity(data){
            if (data.source_type == 12){
                return "Use Referral Code Successfully"
            }
            if (data.source_type == 16){
                return "Invite Friend Successfully"
            }
            let curMission = this.missions.find((mission)=>{
                return mission._id == data.activity
            })
            return curMission ? curMission.name.translations[0].value : data.mission_id
        },
        calTotalEarn(){
            let total = 0;
            this.items.forEach((item)=>{
                if(item.occur_date != "Total")
                    total += item.balance_change
            })
            return total.toFixed(2)
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.occur_date === 'Total') return 'table-success'
        },
        formatDate(date){
            if (date == "Total"){
                return "Total"
            }
            date = new Date(date);
            date.setDate(date.getDate() + 1)
            return new Date(date).toJSON().slice(0,10).split('-').reverse().join('/')
        },
        async exportData(){
            let response = await service.getAll({query: JSON.stringify(this.query)});
            if (response.data.type === "DATA") {
                this.export_data = response.data.data.list;
            }
            this.export_data = this.export_data.map((result)=>{
                return {
                    occur_date: this.formatDate(result.occur_date),
                    balance_change: result.balance_change,
                    activity: this.convertActivity(result)
                }
            })
            this.export_data.unshift({occur_date: "Total", balance_change: this.calTotalEarn(), activity: ""})
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.json_to_sheet(this.export_data, { origin: 'A2', skipHeader: true });
            const header = [['Date','Earned GGT','Activity']];
            XLSX.utils.sheet_add_aoa(ws, header);
            XLSX.utils.book_append_sheet(wb, ws, 'Data');
            XLSX.writeFile(wb, `early_claim.xlsx`);
            this.export_results = [];
        }
    }
}
</script>
<style scoped>
    .click-able:hover {
        cursor: pointer;
        color: #1198F6
    }
</style>