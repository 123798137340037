<template>
  <div>
    <b-row>
      <b-col md="2">
        <b-form-group label="Email" label-for="email">
          <b-form-input
            v-model="dataForm.email"
            id="email"
            type="text"
            placeholder="Email"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Currency" label-for="currency">
          <b-form-select
            v-model="dataForm.currency"
            id="currency"
            :options="currency_options"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" class="d-flex mr-2">
      		<div class="d-flex align-items-center justify-content-center">
				<date-picker
					v-model="dataForm.time_range.start"
					type="date"
					placeholder="Start time"
					:default-value="dataForm.time_range.start"
				/>
				<svg style="width: 2em; height: 2em;"
					class="mx-1"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M14 5l7 7m0 0l-7 7m7-7H3"
				/>
				</svg>
				<date-picker
					v-model="dataForm.time_range.end"
					type="date"
					placeholder="End time"
					:default-value="dataForm.time_range.end"
				/>
			</div>
      </b-col>
      <b-col md="3">
        <b-button @click="search" class="mt-0 mt-md-2 mr-1" variant="primary">
          Search
        </b-button>
        <b-button @click="exportData" class="mt-0 mt-md-2 mr-1" variant="success">
          Export
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
export default {
  components:{
    DatePicker
  },
  data() {
    return {
      dataForm: {
        time_range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        }
      },
      currency_options: [
        {text: "Token", value: 1},
        {text: "Gem", value: 2}
      ]
    };
  },
  methods: {
    search() {
      if (this.dataForm.time_range){
        this.dataForm.time_range.start = new Date(this.dataForm.time_range.start);
        this.dataForm.time_range.end = new Date(this.dataForm.time_range.end);
      }
      this.$emit("getList", this.dataForm);
    },
    exportData(){
      this.$emit("exportData");
    }
  },
};
</script>
